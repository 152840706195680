<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('工单故障类型统计')}}</span>
        <div>
          <el-button type="primary" size="small" @click="getUserList(1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <el-row :gutter="20" class="searchBar">
        <el-col :span="12">
          <el-date-picker v-model="search.date" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="-"
            :start-placeholder="$t('开始日期')" :end-placeholder="$t('结束日期')">
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-select v-model="search.ocid" clearable :placeholder="$t('请选择客户')">
              <el-option
                v-for="item in ocList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-col>
        <el-col :span="6">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
      </el-row>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('故障码')" prop="code" fixed>
        </el-table-column>
        <el-table-column :label="$t('故障类型')" prop="name">
        </el-table-column>
        <el-table-column :label="$t('故障数量')" prop="value">
        </el-table-column>
      </el-table>
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination> -->
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ASFault',
  data () {
    return {
      UntreatedData: [],
      search: {
        date: [],
        ocid: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      ocList: []
    }
  },
  beforeMount () {
    this.getUserList()
  },
  beforeUpdate () {
    this.ocList = this.$store.state.ocList
  },
  methods: {
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    // 获取列表
    getUserList (report) {
      var params = {
        oc_id: this.search.ocid
      }
      if (Array.isArray(this.search.date).length>0) {
        params.start_time = this.search.date[0]
        params.end_time = this.search.date[1]
      } else {
        var time1 = new Date()
        time1.setTime(time1.getTime() - 3600 * 1000 * 24 * 31)
        params.start_time = this.$common.formatTime(time1)
        params.end_time = this.$common.formatTime(new Date())
        this.search.date = [params.start_time, params.end_time]
      }
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'report/dg_fault_statistics')
        return
      }
      this.$axios.post('report/dg_fault_statistics', params).then((res) => {
        // console.log(res)
        const Data = res.data
        if (Data.code === 0) {
          this.UntreatedData = Data.data
          // this.Page = {
          //   pagesize: Data.pagesize,
          //   page: Data.data.page,
          //   maxpage: Data.data.maxpage,
          //   total: Data.data.total
          // }
        } else this.$message.warning(Data.message)
      }).catch((error) => this.$message.error(error))
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  .searchBar {
    margin: 0 0 20px;
  }
  .el-range-editor.el-input__inner{
    width: 100%;
  }
  .el-col{
    margin: 10px 0;
  }
  .el-select{
    width: 100%;
  }
</style>
